.checkin-container-v2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    /* padding: 8px; */
    padding-bottom: 12px;
    /* padding-top: 20%; */
    /* background: rgba(0,0,0,.8); */
    /* background: linear-gradient(
        #061c41,
        #061d45,
        #041e45,
        #041e45,
        #041e45,
        #031d42,
        #011b3c
      ); */
      /* background: url(../../images/checkin_bg.png); */
      min-height: 410px;
      position: relative;
  }

  .checkin-container-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(../../images/checkin_bg.png);
    background-size: 100% auto;
    background-position: 0 0;
  }
  .checkin-container-bottom {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(../../images/checkin_bg.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 0 60%;
    overflow: hidden;
  }
  .checkin-container-bottom img {
    width: 100%;
    height: auto;
    /* transform: translateY(-57%); */
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .checkin-container .title {
    color: white !important;
    font-weight: 500;
    font-size: .9rem;
  }
  
  .day-container {
    display: flex;
    gap: 2px;
    margin-top: 6px;
    width: 100%;
    overflow-x: auto;
  }
  .day-container .day-box-v2 {
    /* width: 25%; */
    /* border-width: 1px;
    border-style: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2C2C2C;
    flex-direction: column;
    border-radius: 7px;
    /* padding: 10px; */
    flex-shrink: 0;
  }
  .day-container .day-box-v2 .day-inner-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .day-inner-box.checked {
    background: rgb(78, 131, 0);
  } */
  .day-box-v2 .day {
    font-size: 1.3rem;
    /* color: #fff !important; */
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: 5px !important;
  }

  .day-box-v2 img {
    max-width: 100%;
  }

  .claim-btn button {
    font-size: 1em;
  }
  .checkin_claim_msg{
    background-color: white;
    border-radius: 15px;
    box-shadow: 
    0 0 12px rgba(230, 150, 12, 0.8), /* Outer shadow */
    0 0 12px rgba(230, 150, 12, 0.8) inset; /* Inner shadow */
    min-height: 87%;
  }
  .checkin_claim_msg p{
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    
  }
  .checkin_claim_box{
    min-height: 400px;
  }

  @media (orientation: portrait) and (max-width: 400px),
  (orientation: landscape) and (max-height: 400px) {
    .claim-btn button{
        font-size: .4em;
    }
  }
